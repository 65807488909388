import OneLinkUrlGenerator from "../../../scripts/oneLinkScript";
import {consoleWrapper, getCookie, getUrlSegments} from "./PagesConstants";
import {getWebsiteEnvironment, STAG_WEB_URL} from "./EnvironmentConstants";

export const APPSFLYER_WINZO_DOT_GAMES = {
    GET_APP_ONELINK : "Xri1"
}

export const APPSFLYER_WINZO_DOT_APP = {
    GET_APP_ONELINK : "SW3N"
}

export function appsFlyerURLGenerator(): string {
    const onelinkGenerator = new OneLinkUrlGenerator(
        {
            oneLinkURL: "https://winzo.onelink.me/D4jJ/",
            pidKeysList: ['utm_source'],
            pidStaticValue: null,
            campaignKeysList: ['utm_campaign'],
            campaignStaticValue: null,
            gclIdParam: 'af_sub5',
            pidOverrideList: {
                utm_dp: 'af_dp',
                utm_medium: 'af_channel',
                utm_keyword: 'af_keywords',
                utm_campaign_id: 'af_c_id',
                utm_adset_id: 'af_adset_id',
                utm_ad_id: 'af_ad_id',
                is_retargeting: 'is_retargeting'
            }
        });
    onelinkGenerator.setAd("utm_ad", "af_ad");
    onelinkGenerator.setAdset("utm_adset", "af_adset");
    onelinkGenerator.setCustomParameter("utm_dp", "af_dp");
    onelinkGenerator.setCustomParameter("utm_adset_id", "af_adset_id");
    onelinkGenerator.setCustomParameter("utm_campaign_id", "af_c_id");
    onelinkGenerator.setCustomParameter("utm_medium", "af_channel");
    onelinkGenerator.setCustomParameter("utm_keyword", "af_keywords");
    onelinkGenerator.setCustomParameter("af_ad_id", "af_adset_id");
    onelinkGenerator.setCustomParameter("is_retargeting", "is_retargeting");

    const incomingFbcid = onelinkGenerator.getParameterValue('fbclid');

    let url = onelinkGenerator.generateUrl();
    let modifiedURL = url;

    const fbpValue = getCookie('_fbp');
    if (fbpValue) {
        const fbcValueArray = fbpValue.split('.');
        const fbcValue = fbcValueArray[0] + '.' + fbcValueArray[1] + '.' + fbcValueArray[2] + '.' + incomingFbcid;

        if (url) {
            modifiedURL = url.concat('&af_sub3=' + fbpValue);
            modifiedURL = modifiedURL.concat('&af_sub4=' + fbcValue);
        }
    }

    return modifiedURL || '';
}

export function appsFlyerURLGeneratorGetAppKwai(architecture: string): string {
    return appsFlyerURLGeneratorGetAppUrl("RaxU", architecture)
}

export function appsFlyerURLGeneratorGetApp(architecture: string, oneLinkId?: string): string {
    return appsFlyerURLGeneratorGetAppUrl(oneLinkId || "2PY4", architecture)
}

function appsFlyerURLGeneratorGetAppUrl(onelinkId: string, architecture: string): string {
    let result: { clickURL: string } | null = null;
    // const onelinkGenerator = new OneLinkUrlGenerator(
    //     {
    //         oneLinkURL: "https://winzo.onelink.me/" + onelinkId,
    //         pidKeysList: ['utm_source'],
    //         pidStaticValue: null,
    //         campaignKeysList: ['utm_campaign'],
    //         campaignStaticValue: null,
    //         gclIdParam: 'af_sub5',
    //         pidOverrideList: {}
    //     });
    //
    // onelinkGenerator.setAd("utm_ad", "af_ad");
    // onelinkGenerator.setAdset("utm_adset", "af_adset");
    // onelinkGenerator.setAfSub3("utm_sub3");
    // onelinkGenerator.setAfSub5("utm_sub5");
    // onelinkGenerator.setCustomParameter("utm_dp", "af_dp");
    // onelinkGenerator.setCustomParameter("utm_retargeting", "is_retargeting");
    // onelinkGenerator.setCustomParameter("utm_term", "af_keywords");
    // onelinkGenerator.setCustomParameter("utm_lookback", "af_click_lookback");
    // onelinkGenerator.setCustomParameter("utm_term", "af_keywords");
    // onelinkGenerator.setCustomParameter("utm", "utm");
    // let url = onelinkGenerator.generateUrl();
    // if (url) {
    //     const newUrl = new URL(url)
    //     newUrl.searchParams.set("af_arch", architecture)
    //     url = newUrl.toString()
    // }
    // console.debug(`Generated OneLink URL ${url}`)
    // return url || '';

    let mediaSource = {keys:["utm_source"]};
    let campaign = {keys:["utm_campaign"]};

    let ad = {keys:["utm_ad"]};
    let adSet = {keys:["utm_adset"]};
    let afSub3 = {keys: ["utm_sub3"]};
    let afSub5 = {keys: ["utm_sub5"]};

    let af_dp = {paramKey:"af_dp", keys: ["utm_dp"]};
    let is_retargeting = {paramKey:"is_retargeting", keys: ["utm_retargeting"]};
    let af_keywords = {paramKey:"af_keywords", keys: ["utm_term"]};
    let af_click_lookback = {paramKey:"af_click_lookback", keys: ["utm_lookback"]};
    let utm = {paramKey:"utm", keys: ["utm"]};

    if (window.AF_SMART_SCRIPT) {
        result = window.AF_SMART_SCRIPT.generateOneLinkURL({
            oneLinkURL: "https://winzo.onelink.me/" + onelinkId,
            afParameters: {
                mediaSource: mediaSource,
                campaign: campaign,
                googleClickIdKey: "af_sub5",
                ad: ad,
                adSet: adSet,
                afSub3: afSub3,
                afSub5: afSub5,
                afCustom: [
                    af_dp,
                    is_retargeting,
                    af_keywords,
                    af_click_lookback,
                    utm
                ]
            },
        })
    }

    let result_url = "No output from script"
    if (result) {
        const newUrl = new URL(result.clickURL)
        newUrl.searchParams.set("af_arch", architecture)
        result_url = newUrl.toString()

        setTimeout(() => {
            window.AF_SMART_SCRIPT.fireImpressionsLink();
            console.log("AppsFlyer", "fire Impressions", result?.clickURL)
        }, 1000);
    }
    return result_url
}

export function appsFlyerLinkDownloadClick(onelinkCode: string, pageName: string): string {
    let url = new URL(window.location.href);
    const onelinkBaseUrl = `https://winzo.onelink.me/${onelinkCode}/`;
    const outGoingUrl = `${onelinkBaseUrl}${url.search}&af_channel=${pageName}`;
    consoleWrapper(outGoingUrl);
    return outGoingUrl || "";
}

function getFbcValue(fbclid: string | null): string {
    const epoch = Date.now();
    const fbcValue: string = "fb.1." + epoch + "." + fbclid;
    return fbcValue;
}

export function appsFlyerLinkWithFbcParam(): string {
    const oneLinkURL: URL = new URL("https://winzo.onelink.me/JyD5")
    let currentUrl = new URL(window.location.href);
    const fbclid: string | null = currentUrl.searchParams.get("fbclid");
    const fbcValue: string = getFbcValue(fbclid);

    for (const [key, value] of currentUrl.searchParams) {
        oneLinkURL.searchParams.set(key, value)
    }

    if (fbclid) {
        oneLinkURL.searchParams.set('fbc', fbcValue)
    }

    if (getCookie('_fbp')) {
        oneLinkURL.searchParams.set('fbp', getCookie('_fbp')!)
    }
    console.debug(oneLinkURL);
    return oneLinkURL.toString();
}

export function appsFlyerLinkWithParam(): string | null {
    const appFlyerLink: string = "https://app.appsflyer.com/com.winzo.gold-Web"
    let currentUrl = new URL(window.location.href);
    const fbclid: string | null = currentUrl.searchParams.get("fbclid");
    const fbcValue: string = getFbcValue(fbclid);
    let outgoingUrl: string | null;
    if (fbclid)
        outgoingUrl = appFlyerLink + currentUrl.search + "&fbc=" + fbcValue + "&af_r=" + currentUrl;
    else
        outgoingUrl = null;
    console.debug(outgoingUrl);
    return outgoingUrl;
}

/**
 * AppsFlyer Script V2 constant Methods Here
 **/
/***
 * CHANDER existing   https://winzo.onelink.me/X7Ss?&c=en-in&pid=organic-home-Variant_A&af_adset=x64&is_retargeting=true&af_inactivity_window=7d
 *
 * CHANDER result_url https://winzo.onelink.me/X7Ss?af_js_web=true&af_ss_ver=2_7_3
 * https://impressions.onelink.me/ch/X7Ss?af_js_web=true&af_ss_ver=2_7_3&af_ch_model=Pixel%25205&af_ch_os_version=13
 *
 * CHANDER result_url https://winzo.onelink.me/X7Ss?af_js_web=true&af_ss_ver=2_7_3&pid=organic-home-Variant_A&c=en-in&af_adset=vu&is_retargeting=true&af_inactivity_window=7d
 * https://impressions.onelink.me/ch/X7Ss?af_js_web=true&af_ss_ver=2_7_3&pid=organic-home-Variant_A&c=en-in&af_adset=x64&is_retargeting=true&af_inactivity_window=7d&af_ch_model=Pixel%25205&af_ch_os_version=13
 * */

export const appsFlyerURLGeneratorOrganicPage = (locale: string, architecture: string, variant ?: string, extraParam ?: {
    key: string,
    value: string
}) => {
    const oneLinkURL: string = `https://winzo.onelink.me/${getWebsiteEnvironment().baseUrl === STAG_WEB_URL ? 'D4jJ' : 'X7Ss'}`;
    const pageParams = getUrlSegments();
    const pageParamWithoutLocale = pageParams.filter(item => item !== locale);
    const length = pageParamWithoutLocale.length;
    const isHomePage: boolean = length ? false: true;
    let campaign: string;
    let result: { clickURL: string } | null = null;

    if (length) {
        const url = pageParamWithoutLocale.join('-');
        campaign = `organic-${url}`;
    } else {
        campaign = `organic-home`;
    }
    if (variant) {
        campaign = campaign + "-" + variant;
    }

    if (getWebsiteEnvironment().baseUrl === STAG_WEB_URL) {
        campaign = campaign + "-stag"
    }

    let afCustomParams = [
        {paramKey: "is_retargeting", defaultValue: true},
        {paramKey: "af_inactivity_window", defaultValue: "7d"},
        {paramKey: "af_reengagement_window", defaultValue: "7d"}
    ]

    if (extraParam) {
        afCustomParams.push({paramKey: extraParam.key, defaultValue: extraParam.value})
    }

    if(isHomePage){
        afCustomParams.push({paramKey: "af_pmod_priority", defaultValue: "equal"})
    }

    if (window.AF_SMART_SCRIPT) {
        result = window.AF_SMART_SCRIPT.generateOneLinkURL({
            oneLinkURL,
            afParameters: {
                campaign: {defaultValue: locale},
                mediaSource: {defaultValue: campaign},
                adSet: {defaultValue: architecture},
                afCustom: afCustomParams
            }
        })
    }

    let result_url = "No output from script"
    if (result) {
        result_url = result.clickURL;
        setTimeout(() => {
            window.AF_SMART_SCRIPT.fireImpressionsLink();
            console.log("AppsFlyer", "fire Impressions", result?.clickURL)
        }, 1000);
    }
    return result_url
}